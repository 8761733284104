const range = require('lodash/range');

const MIN_HEADING_LEVEL = 1;
const MAX_HEADING_LEVEL = 6;

export const levelHeadings = (html, baseLevel) => {
  range(MIN_HEADING_LEVEL, MAX_HEADING_LEVEL + 1).forEach(level => {
    const newLevel = Math.min(level + baseLevel - 1, MAX_HEADING_LEVEL);
    html = html
      .replace(new RegExp(`<h${level}`, 'g'), `<_${newLevel}`)
      .replace(new RegExp(`<\\/h${level}`, 'g'), `</_${newLevel}`);
  });
  return html.replace(/<_(\d)/g, '<h$1').replace(/<\/_(\d)/g, '</h$1');
};
