import React from 'react';
import { levelHeadings } from '../formatters/html';
import styles from './Body.module.scss';

const Body = ({ content }) => (
  <div className={styles.root}>
    <div dangerouslySetInnerHTML={{ __html: levelHeadings(content, 2) }} />
  </div>
);

export default Body;
