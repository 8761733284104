export const formatComparisons = data => data.allMarkdownRemark.nodes.map(format);

export const formatComparison = data => format(data.markdownRemark);

function format({ id, fields, frontmatter, html, ...other }) {
  return {
    id,
    ...fields,
    ...frontmatter,
    body: html,
    ...other,
  };
}
